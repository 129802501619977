import * as React from "react"
import { graphql, Link } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"
import LayoutSectionIndex from "~templates/layout-section_index"
import DisplayGuidesInPrimary, {
  make_primary_header_card,
  grouped_guides_to_sec_cards,
  primary_orphans_to_cards,
} from "~components/guides_in_primary"
import NavGuideData from "~data/nav_full_site_delivery.yml"
import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"
import { CommonHead } from "~components/Head/CommonHead"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as Includes from "~components/includes"
import Frontmatter from "~components/frontmatter"

let frontmatter = {
  lang: "ja",
  section: "full_site_delivery",
  section_safe: "full_site_delivery",
  home_url: "/",
  guides_url: "/ja/guides",
  section_url: "/ja/guides/full-site-delivery/",
  crumb2override: "Full-Site Delivery",
  fileslug: "full-site-delivery",
  title: "Full-Site Delivery | Fastly Documentation",
  i18n: ["en"],
}

const JaFullsitedeliveryIndexPage = ({ data, location }) => {
  let PrimaryDatas = []
  NavGuideData.forEach((element) => {
    const { crumb: pkey, "secondary-nav": snav = [] } = element

    let thisPrimary = data.primaries.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this
    p_frontmatter["section_url"] = "/ja/guides/"

    let thisPrimaryGuides = data.guides_in_primarys.group.find((ggip) => ggip.fieldValue === pkey)

    let myOrphans = data.primary_orphans.group.find((g) => g.fieldValue === pkey)

    PrimaryDatas.push({
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      pbody: thisPrimary.body,
      sec_cards: grouped_guides_to_sec_cards(p_frontmatter, thisPrimaryGuides?.nodes, data.secondaries.nodes, snav),
      primary_orphans: primary_orphans_to_cards(p_frontmatter, myOrphans),
    })
  })

  let mdx_components = {Frontmatter, ...Includes}

  let title_content = {
    title: "Full-Site Delivery",
    desc: "これらの記事では、Fastly の CDN の使用方法について説明します。",
    icon: "globe"
  }

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutSectionIndex location={location} title_content={title_content}>

          {PrimaryDatas.map((datum) => {
            const { pfrontmatter, pbody, sec_cards, primary_orphans } = datum
            return (
              <DisplayGuidesInPrimary
                frontmatter={pfrontmatter}
                body={
                  <FrontmatterPageContext.Provider value={pfrontmatter}>
                    <MDXProvider components={mdx_components} >
                      <MDXRenderer>{pbody}</MDXRenderer>
                    </MDXProvider>
                  </FrontmatterPageContext.Provider>}
                sec_cards={sec_cards}
                primary_orphans={primary_orphans}
              />
            )
          })}
        </LayoutSectionIndex>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default JaFullsitedeliveryIndexPage

export const query = graphql`
  query {
    guides_in_primarys: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/_ja_full_site_delivery//" }
        frontmatter: { unlisted: { ne: true }, orphan: { ne: true } }
      }
      sort: { fields: [frontmatter___order, slug], order: [ASC, ASC] }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
          }
        }
      }
    }
    primaries: allMdx(filter: { fileAbsolutePath: { regex: "/_ja_full_site_delivery_primary/" } }) {
      nodes {
        fileAbsolutePath
        body
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    secondaries: allMdx(filter: { fileAbsolutePath: { regex: "/_ja_full_site_delivery_secondary/" } }) {
      nodes {
        fileAbsolutePath
        slug
        # body
        frontmatter {
          name
          crumb
          description
        }
      }
    }
    primary_orphans: allMdx(
      filter: {
        frontmatter: { orphan: { eq: true }, unlisted: { ne: true } }
        fileAbsolutePath: { regex: "/_ja_full_site_delivery//" }
      }
      sort: { fields: [frontmatter___order, slug], order: [ASC, ASC] }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          excerpt
          frontmatter {
            primary_nav
            # secondary_nav
            header
            # unlisted
            # orphan
          }
        }
      }
    }
  }
`

export const Head = (props) => {
  return <>
    <title>{frontmatter.title}</title>
    <CommonHead />
    <SeoHead frontmatter={frontmatter} {...props} />
    <FavHead />
  </>
}